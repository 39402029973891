.ui-text-button {
  @apply font-sans;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 0.5rem;

  font-style: normal;
  font-weight: 700;

  cursor: pointer;

  position: relative;
}

.ui-text-button__pending {
  @apply invisible;
}

.ui-text-button__spinner {
  @apply visible animate-spin;
}

.ui-text-button__spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ui-text-button__normal {
  @apply text-isa-neutral-900;

  &:hover {
    @apply text-isa-neutral-700;
  }
}

.ui-text-button__strong {
  @apply text-isa-accent-blue;

  &:hover {
    @apply text-isa-secondary-500;
  }
}

.ui-text-button__subtle {
  @apply text-isa-neutral-900;
  text-decoration-line: underline;
  text-decoration-style: solid;
  text-decoration-skip-ink: none;
  text-decoration-thickness: auto;
  text-underline-offset: auto;
  text-underline-position: from-font;

  &:hover {
    @apply text-isa-neutral-700;
  }
}

.ui-text-button:disabled,
.ui-text-button[disabled],
.ui-text-button.disabled {
  @apply text-isa-neutral-400 cursor-default;
}

.ui-text-button__small {
  padding: 0.375rem 0.75rem;
  font-size: 0.75rem;
  line-height: 1rem; /* 133.333% */
}

.ui-text-button__medium {
  padding: 0.5rem 1.25rem;
  font-size: 0.875rem;
  line-height: 1.25rem; /* 142.857% */
}

.ui-text-button__large {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
}
