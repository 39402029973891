.ui-button {
  @apply font-sans cursor-pointer;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  font-style: normal;
  font-weight: 700;
  border-radius: 6.25rem;

  position: relative;
}

.ui-button__pending {
  @apply invisible;
}

.ui-button__spinner {
  @apply visible animate-spin;
}

.ui-button__spinner-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.ui-button__small {
  min-width: 10rem;
  padding: 0.375rem 0.75rem;

  font-size: 0.875rem;
  line-height: 1.25rem; /* 142.857% */
}

.ui-button__medium {
  height: 2.5rem;
  min-width: 10rem;
  padding: 0.5rem 1.25rem;
  flex-shrink: 0;

  font-size: 0.875rem;
  line-height: 1.25rem; /* 142.857% */
}

.ui-button__large {
  min-width: 10rem;
  padding: 0.75rem 1.5rem;

  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
}

.ui-button__primary {
  @apply bg-isa-secondary-600 text-isa-white;

  &:hover {
    @apply bg-isa-secondary-700;
  }
  &:active,
  &.active {
    @apply bg-isa-secondary-800;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-400;
  }

  .ui-button__spinner {
    @apply text-isa-white;
  }
}

.ui-button__secondary {
  @apply border border-solid border-isa-secondary-600 text-isa-secondary-600;

  &:hover {
    @apply border-isa-secondary-700 bg-isa-neutral-100 text-isa-secondary-700;
  }
  &:active,
  &.active {
    @apply border-isa-secondary-800 bg-isa-neutral-200 text-isa-secondary-800;
  }
  &:disabled,
  &[disabled],
  &.disabled {
    @apply border-isa-neutral-400 text-isa-neutral-400 bg-isa-white;
  }

  .ui-button__spinner {
    @apply text-isa-secondary-600;
  }
}

.ui-button__brand {
  @apply bg-isa-accent-red text-isa-white;

  &:hover {
    @apply bg-isa-shades-red-600;
  }

  &:active,
  &.active {
    @apply bg-isa-shades-red-700;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-400;
  }
}

.ui-button__tertiary {
  @apply bg-isa-neutral-300 text-isa-neutral-900;

  &:hover {
    @apply bg-isa-neutral-400;
  }

  &:active,
  &.active {
    @apply bg-isa-neutral-500 text-isa-white;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-200 text-isa-neutral-500;
  }

  .ui-button__spinner {
    @apply text-isa-neutral-600;
  }
}

.ui-button:disabled,
.ui-button[disabled],
.ui-button.disabled {
  @apply cursor-default;
}
