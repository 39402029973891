.ui-info-button {
  display: inline-flex;
  padding-left: 1rem;
  align-items: center;
  gap: 1rem;

  @apply rounded-full cursor-pointer bg-isa-neutral-100 isa-text-body-1-bold text-isa-neutral-900;

  ng-icon {
    display: flex;
    padding: 0.75rem;
    justify-content: center;
    align-items: center;

    @apply rounded-full size-12;
  }

  ng-icon[uiInfoButtonLabel] {
    @apply -mx-3;
  }

  ng-icon[uiInfoButtonIcon] {
    @apply bg-isa-neutral-300;
  }

  &:hover {
    ng-icon[uiInfoButtonIcon] {
      @apply bg-isa-neutral-400;
    }
  }

  &:active,
  &.active {
    ng-icon[uiInfoButtonIcon] {
      @apply text-isa-white bg-isa-neutral-500;
    }
  }

  &:disabled,
  &[disabled] {
    @apply bg-isa-neutral-200 text-isa-neutral-500 cursor-not-allowed;
  }
}
