.ui-icon-button {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  @apply rounded-full cursor-pointer bg-isa-white;
}

.ui-icon-button__small {
  height: 2.5rem;
  width: 2.5rem;
  padding: 0.5rem;
  @apply text-xl;
}

.ui-icon-button__medium {
  padding: 0.75rem;
  @apply text-2xl;
}

.ui-icon-button__primary {
  @apply bg-isa-secondary-600 text-isa-white;

  &:hover {
    @apply bg-isa-secondary-700;
  }
  &:active,
  &.active {
    @apply bg-isa-secondary-800;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-400;
  }
}

.ui-icon-button__secondary {
  @apply border border-solid border-isa-secondary-600 text-isa-secondary-600;

  &:hover {
    @apply border-isa-secondary-700 bg-isa-neutral-100 text-isa-secondary-700;
  }
  &:active,
  &.active {
    @apply border-isa-secondary-800 bg-isa-neutral-200 text-isa-secondary-800;
  }
  &:disabled,
  &[disabled],
  &.disabled {
    @apply border-isa-neutral-400 text-isa-neutral-400 bg-isa-white;
  }
}

.ui-icon-button__brand {
  @apply bg-isa-accent-red text-isa-white;

  &:hover {
    @apply bg-isa-shades-red-600;
  }

  &:active,
  &.active {
    @apply bg-isa-shades-red-700;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-400;
  }
}

.ui-icon-button__tertiary {
  @apply bg-isa-neutral-300 text-isa-neutral-900;

  &:hover {
    @apply bg-isa-neutral-400;
  }

  &:active,
  &.active {
    @apply bg-isa-neutral-500 text-isa-white;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply bg-isa-neutral-200 text-isa-neutral-500;
  }
}

.ui-icon-button__neutral {
  @apply bg-isa-white text-isa-neutral-500;

  &:hover {
    @apply text-isa-neutral-700;
  }

  &:disabled,
  &[disabled],
  &.disabled {
    @apply text-isa-neutral-400 cursor-default;
  }
}

.ui-icon-button:disabled,
.ui-icon-button[disabled],
.ui-icon-button.disabled {
  @apply cursor-default;
}
