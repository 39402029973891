.ui-text-field {
  display: inline-flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem 1.25rem;

  @apply border border-solid border-isa-neutral-600 rounded-full;
  @apply bg-white text-isa-neutral-900;
}

.ui-input-control {
  flex: 1;
  appearance: none;

  @apply isa-text-body-2-regular focus:outline-none;
  @apply placeholder:text-neutral-500 hover:placeholder:text-neutral-900 focus:placeholder:text-neutral-900;
}

.ui-text-field__medium {
  height: 3rem;
  flex-shrink: 0;
}

.ui-text-field__large {
  height: 3.5rem;
  flex-shrink: 0;
}
