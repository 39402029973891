.ui-selected-range {
  @apply w-full h-[4.5rem] flex items-end justify-center border-b-[0.0625rem] border-isa-neutral-400 pb-[0.75rem];
}

.ui-selected-range__input-wrapper {
  @apply relative flex flex-col gap-1;

  label {
    @apply text-isa-neutral-900 text-[0.5rem] font-bold leading-[0.625rem] uppercase;
  }

  input {
    @apply w-[6.25rem] isa-text-body-1-bold outline-none;
  }

  input::placeholder {
    @apply text-isa-neutral-500 isa-text-body-1-regular;
  }

  .ui-selected-range__start-focus-indicator,
  .ui-selected-range__stop-focus-indicator {
    @apply hidden absolute -bottom-[0.77rem] w-[5.125rem] h-[0.25rem] bg-isa-neutral-700 rounded-[3.125rem];
  }

  &:has(input#start:focus) {
    .ui-selected-range__start-focus-indicator {
      @apply block;
    }
  }

  &:has(input#stop:focus) {
    .ui-selected-range__stop-focus-indicator {
      @apply block;
    }
  }
}
