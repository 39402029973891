.ui-selected-month-year {
  @apply w-full h-[4.5rem] px-4 flex flex-row items-center justify-between border-b-[0.0625rem] border-isa-neutral-400;
}

.ui-selected-month-year__month-cta {
  @apply px-4 py-2 flex flex-row gap-2 items-center justify-center text-isa-neutral-900 isa-text-body-2-bold border border-solid border-transparent;

  &.selected {
    @apply border border-solid border-isa-neutral-900 rounded-[3.125rem];
  }
}

.ui-selected-month-year__year-cta {
  @apply px-4 py-2 flex flex-row gap-2 items-center justify-center text-isa-neutral-900 isa-text-body-2-bold border border-solid border-transparent;

  &.selected {
    @apply border border-solid border-isa-neutral-900 rounded-[3.125rem];
  }
}
