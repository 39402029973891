.ui-dropdown.ui-dropdown__button {
  display: inline-flex;
  height: 3rem;
  padding: 0rem 1.5rem;
  align-items: center;
  gap: 0.5rem;
  flex-shrink: 0;
  cursor: pointer;

  border-radius: 3.125rem;
  @apply text-isa-accent-blue isa-text-body-2-bold border border-solid border-isa-accent-blue;

  ng-icon {
    @apply size-6;
  }

  &:hover {
    @apply bg-isa-neutral-100 border-isa-secondary-700;
  }

  &:active {
    @apply border-isa-accent-blue;
  }

  &.open {
    @apply border-transparent;
  }
}

.ui-dropdown__options {
  // Fixed typo from ui-dorpdown__options
  display: inline-flex;
  padding: 0.25rem;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 1.25rem;
  background: var(--Neutral-White, #fff);
  box-shadow: 0px 0px 16px 0px rgba(0, 0, 0, 0.15);
  width: 100%;

  .ui-dropdown-option {
    display: flex;
    width: 10rem;
    height: 3rem;
    padding: 0rem 1.5rem;
    flex-direction: column;
    justify-content: center;
    gap: 0.625rem;
    border-radius: 0.5rem;
    word-wrap: none;
    white-space: nowrap;
    cursor: pointer;
    width: 100%;

    @apply isa-text-body-2-bold;

    &.active,
    &:focus,
    &:hover {
      @apply bg-isa-neutral-200;
    }

    &.selected {
      @apply text-isa-accent-blue;
    }
  }
}
