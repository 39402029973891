.ui-checkbox.ui-checkbox__checkbox {
  @apply relative inline-flex p-3 items-center justify-center rounded-lg bg-isa-white size-6 border border-solid border-isa-neutral-900;
  font-size: 1.5rem;

  .ui-checkbox__icon {
    @apply invisible min-w-6 size-6 text-isa-white;
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    @apply cursor-pointer;
  }

  &:has(input[type="checkbox"]:checked) {
    @apply bg-isa-neutral-900 text-isa-white;

    .ui-checkbox__icon {
      @apply visible;
    }
  }

  &:has(input[type="checkbox"]:disabled) {
    @apply bg-isa-neutral-400 border-isa-neutral-400 cursor-default;

    &:has(input[type="checkbox"]:checked) {
      @apply bg-isa-neutral-400 border-isa-neutral-400;
    }

    input[type="checkbox"] {
      @apply cursor-default;
    }
  }
}

.ui-checkbox.ui-checkbox__bullet {
  display: inline-flex;
  padding: 0.75rem;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem;
  position: relative;

  @apply rounded-full bg-isa-neutral-300 size-12;

  .ui-checkbox__icon {
    @apply invisible size-6 text-isa-neutral-100;
  }

  input[type="checkbox"] {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    @apply cursor-pointer;
  }

  &:hover {
    @apply bg-isa-neutral-400;
  }

  &:has(input[type="checkbox"]:checked) {
    @apply bg-isa-neutral-700;

    .ui-checkbox__icon {
      @apply visible;
    }

    &:hover {
      @apply bg-isa-neutral-800;
    }
  }

  &:has(input[type="checkbox"]:disabled) {
    @apply bg-isa-neutral-400 cursor-default;

    &:has(input[type="checkbox"]:checked) {
      @apply bg-isa-neutral-700;
    }

    input[type="checkbox"] {
      @apply cursor-default;
    }
  }
}
