// Styles for the ui-chips container
// This container aligns chip options horizontally with spacing between them.
.ui-chips {
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

// Styles for individual chip options
// Defines the appearance and layout of each chip option.
.ui-chip-option {
  display: inline-flex;
  height: 2.5rem;
  min-width: 10rem;
  padding: 0.5rem 1.25rem;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 6.25rem;
  @apply border border-solid border-neutral-600;

  @apply isa-text-body-2-bold text-isa-neutral-600 cursor-pointer;
}

// Styles for a selected chip option
// Applies background and text color changes when a chip is selected.
.ui-chip-option__selected {
  @apply bg-isa-neutral-200 text-isa-neutral-800;
}

// Styles for a disabled ui-chips container
// Reduces opacity and disables pointer events when the container is disabled.
.ui-chips__disabled {
  opacity: 0.5;
  pointer-events: none;
}
