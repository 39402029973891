.ui-calendar-body {
  @apply flex flex-col w-full pt-8 pb-6 gap-4;
}

.ui-calendar-body__cell-container {
  @apply grid grid-cols-[repeat(7,2.375rem)] justify-center;
}

.ui-calendar-body__cell {
  @apply relative w-full h-full flex items-center justify-center text-isa-neutral-900 isa-text-body-1-semibold p-2 leading-4 aspect-square;
  z-index: 10;

  &.day-label {
    @apply text-isa-neutral-400;
  }

  &.today {
    @apply text-isa-accent-blue;

    &:hover {
      @apply text-isa-secondary-700;
    }
  }

  &.in-range:not(.selected) {
    @apply bg-isa-secondary-200;
  }

  &.selected {
    @apply rounded-[2.5rem] bg-isa-accent-blue text-isa-white;
    z-index: 10;

    &:hover {
      @apply bg-isa-secondary-700 text-isa-white;
    }

    // Styling für Date-Range
    &.start::before,
    &.end::before {
      content: '';
      @apply absolute inset-0 bg-isa-secondary-200;
      z-index: -1;
      width: 50%;
    }

    // Styling für das selektierte Start Element von Date-Range
    &.start::after {
      content: '';
      @apply absolute inset-0 bg-isa-secondary-200;
      z-index: -2;
      left: 50%;
    }
    &.start::before {
      @apply bg-isa-accent-blue rounded-r-[2.5rem];
      left: 50%;
      z-index: -1;

      &:hover {
        @apply bg-isa-secondary-700;
      }
    }

    // Styling für das selektierte Bis Element von Date-Range
    &.end::after {
      content: '';
      @apply absolute inset-0 bg-isa-secondary-200;
      z-index: -2;
      right: 50%;
    }
    &.end::before {
      @apply bg-isa-accent-blue rounded-l-[2.5rem];
      right: 50%;
      z-index: -1;

      &:hover {
        @apply bg-isa-secondary-700;
      }
    }
  }

  &:disabled {
    @apply text-isa-neutral-200 cursor-not-allowed;
  }

  &:hover:not(.day-label):not(.selected):not(.today):not(.in-range):not(
      :disabled
    ) {
    @apply rounded-[2.5rem] bg-isa-secondary-100;
  }
}

.ui-calendar-body__reset-cta {
  @apply py-2 text-isa-neutral-900 underline isa-text-body-2-bold;

  &:disabled {
    @apply text-isa-neutral-400;
  }
}
