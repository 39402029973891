.ui-month-year-body {
  @apply flex flex-col w-full gap-4;
}

.ui-month-year-body__list {
  @apply min-h-80 max-h-80 flex flex-col overflow-hidden overflow-y-scroll px-1;
}

.ui-month-year-body__list-element {
  @apply h-12 rounded-2xl flex px-6 py-[0.88rem] items-center justify-between isa-text-body-2-bold text-isa-neutral-700;

  &.selected {
    @apply bg-isa-neutral-200 text-isa-neutral-900;
  }
}

.ui-month-body__actions {
  @apply flex flex-row items-center justify-center gap-2 my-3;

  button {
    @apply w-[8.125rem] max-w-[8.125rem] min-w-[8.125rem];
  }
}
