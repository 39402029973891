@layer components {
  .icon {
    --icon-fill: 0;
    --icon-weight: 400;
    --icon-grade: 0;
    --icon-optical-size: 48;
  }

  .icon,
  .icon-outlined {
    font-family: "Material Symbols Outlined";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }

  .icon-rounded {
    font-family: "Material Symbols Rounded";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }

  .icon-sharp {
    font-family: "Material Symbols Sharp";
    font-weight: normal;
    font-style: normal;
    font-size: 24px;
    line-height: 1;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    -webkit-font-feature-settings: "liga";
    -webkit-font-smoothing: antialiased;
  }

  .icon-fill {
    --icon-fill: 1;
  }

  .icon-weight-100 {
    --icon-weight: 100;
  }

  .icon-weight-200 {
    --icon-weight: 200;
  }

  .icon-weight-300 {
    --icon-weight: 300;
  }

  .icon-weight-400 {
    --icon-weight: 400;
  }

  .icon-weight-500 {
    --icon-weight: 500;
  }

  .icon-weight-600 {
    --icon-weight: 600;
  }

  .icon-weight-700 {
    --icon-weight: 700;
  }

  .icon-grade {
    --icon-grade: 0;
  }

  .icon-grade-200 {
    --icon-grade: 200;
  }

  .icon-grade--25 {
    --icon-grade: -25;
  }

  .icon-optical-size-20 {
    --icon-optical-size: 20;
  }

  .icon-optical-size-24 {
    --icon-optical-size: 24;
  }

  .icon-optical-size-40 {
    --icon-optical-size: 40;
  }

  .icon-optical-size-48 {
    --icon-optical-size: 48;
  }

  .icon {
    font-variation-settings:
      "FILL" var(--icon-fill),
      "wght" var(--icon-weight),
      "GRAD" var(--icon-grade),
      "opsz" var(--icon-optical-size);
  }
}
