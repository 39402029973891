.ui-progress-bar {
  display: inline-flex;
  height: 0.5rem;
  width: 10rem;

  @apply bg-isa-neutral-300 rounded-full;
}

.ui-progress-bar__bar {
  @apply inline-flex h-full bg-isa-accent-blue rounded-full;
}

.ui-progress-bar__determinate {
  .ui-progress-bar__bar {
    transition: width 300ms ease-in-out;
  }
}

.ui-progress-bar__indeterminate {
  overflow: hidden;
  .ui-progress-bar__bar {
    animation: progress-bar-indeterminate 3s infinite;
  }
}

@keyframes progress-bar-indeterminate {
  0% {
    transform: translateX(-95%);
  }
  50% {
    transform: translateX(95%);
  }
  100% {
    transform: translateX(-95%);
  }
}
